.htp-pulse-1 {
  transition: opacity 500ms;
  animation: htpPulse1 9s ease-in-out infinite;
}
.htp-pulse-2 {
  transition: opacity 500ms;
  animation: htpPulse2 9s ease-in-out infinite;
}
.htp-pulse-3 {
  transition: opacity 500ms;
  animation: htpPulse3 9s ease-in-out infinite;
}

@keyframes htpPulse1 {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 100%;
  }
  25% {
    opacity: 100%;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes htpPulse2 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  42% {
    opacity: 1;
  }
  58% {
    opacity: 1;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes htpPulse3 {
  0% {
    opacity: 0;
  }
  67% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.step-pulse-1 {
  transition: background-color 500ms;
  animation: stepPulse1 9s ease-in-out infinite;
}
.step-pulse-2 {
  transition: background-color 500ms;
  animation: stepPulse2 9s ease-in-out infinite;
}
.step-pulse-3 {
  transition: background-color 500ms;
  animation: stepPulse3 9s ease-in-out infinite;
}

@keyframes stepPulse1 {
  0% {
    background-color: #44403c; /* --tw-stone-700 */
  }
  8% {
    background-color: var(--amber-v1);
  }
  25% {
    background-color: var(--amber-v1);
  }
  33% {
    background-color: #44403c;
  }
  100% {
    background-color: #44403c;
  }
}
@keyframes stepPulse2 {
  0% {
    background-color: #44403c;
  }
  33% {
    background-color: #44403c;
  }
  42% {
    background-color: var(--amber-v1);
  }
  58% {
    background-color: var(--amber-v1);
  }
  67% {
    background-color: #44403c;
  }
  100% {
    background-color: #44403c;
  }
}
@keyframes stepPulse3 {
  0% {
    background-color: #44403c;
  }
  67% {
    background-color: #44403c;
  }
  75% {
    background-color: var(--amber-v1);
  }
  92% {
    background-color: var(--amber-v1);
  }
  100% {
    background-color: #44403c;
  }
}
